export default {
    state: {
        data: {},
    },
    mutations: {
        SET_APP(state, newData) {
            state.data = newData;
        },
        UPDATE_APP(state, newData) {
            state.data[newData.key] = newData.value;
        },
    },
    actions: {
        async appData({commit}, newData) {
            commit("SET_APP", newData);
        },
        async appUpdate({commit}, newData) {
            commit("UPDATE_APP", newData);
        },
    },
    getters: {
        app(state) {
            return state.data;
        },
    },
};
