<template>
  <div class="container">
    <div class="">
      <Navbar />

      <toastr />
    </div>
    <div class="pr-3 pl-3">
      <router-view />
    </div>
    <Sidebar />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
  created() {
    this.start();
  },
  methods: {
    start() {
      setInterval(() => {
        this.$store.dispatch("run");
      }, 1000);
    },
  },
};
</script>

<style>
@import "assets/css/root.css";
@import "assets/css/style.css";
@import "assets/css/icon.css";
</style>
