import axios from "axios";

const baseUrl = "https://" + window.location.hostname + ":3000";
export async function post(event, param) {
    try {
        let url;
        let data;
        if (param == "file") {
            event.preventDefault();
            const form = event.target.closest("form");
            const formData = new FormData(form);
            url = form.id;

            data = await go(url, formData, "", this.$cookies);
        } else if (param == "submit") {
            event.preventDefault();
            const form = event.target.closest("form");
            const invate = this.$cookies.get("ref") || false;

            if (form) {
                const formData = new FormData(form);
                if (invate) formData.append("ref", invate);

                const jsonData = {};
                formData.forEach((value, key) => {
                    jsonData[key] = value;
                });
                const jsonBody = JSON.stringify(jsonData) || {};

                const submitBtn = form.querySelector("[type=submit]");
                submitBtn.disabled = true;
                submitBtn.insertAdjacentHTML("beforeend", '<div class="spinner-border spinner-border-sm"></div>');
                url = form.id;
                data = await go(url, jsonBody, "application/json", this.$cookies);
                submitBtn.disabled = false;
                submitBtn.querySelector(".spinner-border").remove();
            }
        } else {
            url = event;
            const jsonBody = JSON.stringify(param) || {};
            data = await go(url, jsonBody, "application/json", this.$cookies);
        }

        if (data && data.token) {
            this.$cookies.set("token", data.token, {
                path: "/",
                secure: true,
                sameSite: "strict",
            });
        }
        if (data && data.user) {
            this.$store.dispatch("userData", data.user);
        }
        if (data && data.updateUser) {
            this.$store.dispatch("updateUser", data.updateUser);
        }
        if (data && data.updateWallet) {
            this.$store.dispatch("updateWallet", data.updateWallet);
        }
        if (data && data.mint) {
            this.$store.dispatch("mintData", data.mint);
        }
        if (data && data.push && data.push.text) {
            this.$toast.show(data.push.text, {type: data.push.type});
        }
        if (data && data.wallet) {
            this.$store.dispatch("walletData", data.wallet);
        }
        if (data && data.app) {
            this.$store.dispatch("appData", data.app);
        }

        return data;
    } catch (error) {
        this.$toast.show(`Error: ${error}`, {type: "error"});
        return;
    }
}

async function go(url, body, type, cookies) {
    const token = cookies.get("token") || "";

    try {
        // const response = await fetch(baseUrl + url, {
        //     method: "POST",
        //     headers: {
        //         ...(type && {"Content-Type": type}),
        //         Authorization: `Bearer ${token}`,
        //     },
        //     body: body,
        // });
        const response = await axios.post(baseUrl + url, body, {
            headers: {
                ...(type && {"Content-Type": type}),
                Authorization: `Bearer ${token}`
            },
        });

        return response.data;
    } catch (error) {
        console.error("Fetch Error");
        return;
    }
}
