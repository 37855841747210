export default {
  state: {
    userData: {
      id: 1,
      lvl: 1,
      player: 1,
      playerData: { 1: 1, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 },
      per: 0,
      mine: 0,
      claim: Date.now() - 10000,
      gold: 10000,
      silver: 1000,
      referals: 0,
    },
    player: { 1: 1, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 },
    playerData: {
      1: { 1: 100, 2: 200, 3: 400, 4: 800, 5: 11600 },
      2: { 1: 1600, 2: 3200, 3: 6400, 4: 12800, 5: 25600 },
      3: { 1: 100, 2: 200, 3: 400, 4: 800, 5: 1600 },
      4: { 1: 100, 2: 200, 3: 400, 4: 800, 5: 1600 },
      5: { 1: 100, 2: 200, 3: 400, 4: 800, 5: 1600 },
      6: { 1: 100, 2: 200, 3: 400, 4: 800, 5: 1600 },
    },
    perkData: [{ name: "", img: "", type: "", bonus: "" }],
    date: Date.now(),
    auth: false,
    dataLoaded: false,
  },
  mutations: {
    SET_RUN(state) {
      state.userData.per =
        state.playerData[state.userData.player][
          state.userData.playerData[state.userData.player]
        ];
    },
    SET_LOAD(state) {
      state.dataLoaded = true;
    },
    UPDATE_CLAIM(state) {
      state.userData.claim = Date.now();
      state.userData.gold += state.userData.mine;
      state.userData.mine = 0;
    },
    UPDATE_MINE(state) {
      // Вычисляем доход за одну секунду
      const incomePerSecond = state.userData.per / 3600;
      const now = Date.now();
      const diffInSeconds = Math.floor((now - state.userData.claim) / 1000); // разница во времени в секундах

      // Обновляем баланс
      if (diffInSeconds > 0) {
        const currentBalance = Math.floor(diffInSeconds * incomePerSecond);
        state.userData.mine += currentBalance;
        state.userData.claim = now;
      }
    },
    UPDATE_UPGRADE(state, id) {
      if (state.userData.playerData[id] < 5) {
        state.userData.gold -= state.playerData[1][state.userData.playerData[1]] * 2;
        state.userData.playerData[id] += 1;
      }
    },
  },
  actions: {
    async run({ commit }, newData) {
      await commit("SET_RUN");
      commit("UPDATE_MINE");
    },
    async load({ commit }, newData) {
      commit("SET_LOAD");
    },
    async claim({ commit }) {
      commit("UPDATE_CLAIM");
    },
    async upgrade({ commit }, id) {
      commit("UPDATE_UPGRADE", id);
    },
  },
  getters: {
    user(state) {
      const data = state.userData;
      const auth = state.auth;
      const dataLoaded = state.dataLoaded;
      const playerData = state.playerData
      return { data, auth, dataLoaded, playerData };
    },
  },
};
