import {createRouter, createWebHistory} from "vue-router";
import store from "./store";
import {GlobalMethods} from "./global/main.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("./views/Home.vue"),
    meta: {},
  },
  {
    path: "/invate",
    name: "invate",
    component: () => import("./views/Invate.vue"),
    meta: {},
  },
  {
    path: "/player",
    name: "player",
    component: () => import("./views/Player.vue"),
    meta: {},
  },
  {
    path: "/quests/:mod?",
    name: "quests",
    component: () => import("./views/Quests.vue"),
    meta: {},
  },
  {
    path: "/shop/:mod?",
    name: "shop",
    component: () => import("./views/Shop.vue"),
    meta: {},
  },
  {
    path: "/top",
    name: "top",
    component: () => import("./views/Top.vue"),
    meta: {},
  },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// router.beforeEach((to, from, next) => {
//     const waitForDataLoaded = () => {
//         return new Promise((resolve) => {
//             const unwatch = store.watch(
//                 (state, getters) => getters.user.dataLoaded,
//                 (newValue) => {
//                     if (newValue === true) {
//                         unwatch();
//                         resolve();
//                     }
//                 },
//             );
//         });
//     };

//     // Если данные пользователя уже загружены, продолжаем навигацию
//     if (store.getters.user.dataLoaded) {
//         return next();
//     }

//     // В противном случае ждем загрузку данных пользователя
//     waitForDataLoaded()
//         .then(() => {
//             next(); // Продолжаем навигацию после успешной загрузки данных
//         })
//         .catch((error) => {
//             console.error("Ошибка при ожидании загрузки данных пользователя:", error);
//             next(false); // Останавливаем навигацию при ошибке
//         });
// });

router.beforeResolve((to, from, next) => {
    const requireAuth = to.meta.auth || false;
    const requirePrava = to.meta.prava || false;

    window.scrollTo(0, 0);
    if ((requireAuth && !store.getters.user.auth) || (store.getters.user && requirePrava && requirePrava > store.getters.user.data.prava)) {
        GlobalMethods.$modal("loginModal");
        next(false);
    } else {
        next();
    }
});

export default router;
