import {mapGetters} from "vuex";

let checkUserCalled = false;

export const userMixin = {
    computed: {
        ...mapGetters(["user"]),
    },
    created() {
        if (!checkUserCalled) {
            this.checkUser();
            checkUserCalled = true;
        }
    },
    methods: {
        async checkUser() {
            try {
                const data = await this.$post("/auth", {referrer: document.referrer});
                if (data) {
                    this.$store.dispatch("load");
                }
            } catch (error) {
                console.error("Ошибка при проверке пользователя:", error);
            }
        },
    },
};
