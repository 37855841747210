import { createI18n } from "vue-i18n";
import cookies from "vue-cookies";

// Импортируем файлы с переводами
import ru from "../lang/ru.json";
import en from "../lang/en.json";
import ua from "../lang/ua.json";

const lang = cookies.get("lang") || 'en';

const messages = {
  en,
  ru,
  ua,
};

const i18n = createI18n({
    locale: lang,
    fallbackRoot: true,
    fallbackLocale: "en",
    silentFallbackWarn: true,
    messages,
});

export default i18n; // Экспортируем экземпляр i18n
