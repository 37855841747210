<template>
  <div class="navbar">
    <div class="">
      <router-link to="/" class="m-3"><i class="icon x54 i-profile"></i></router-link>
    </div>
    <div class="">
      <router-link to="/" class="m-3"><i class="icon x54 i-wallet"></i></router-link>
      <router-link to="/" class="m-3"><i class="icon x54 i-what"></i></router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: new Date(),
    };
  },
  mounted() {
    window.addEventListener("mousedown", this.handleMouseDown);
  },
  methods: {
    formatPrice(price) {
      if (typeof price === "string") {
        price = parseFloat(price);
      }
      return price.toFixed(4);
    },
    subMenuShow(id) {
      if (this.subMenu == id) {
        this.subMenu = "";
      } else if (this.subMenu != "" || this.subMenu != id) {
        this.subMenu = id;
      }
    },
    handleMouseDown(event) {
      if (this.subMenu != "") {
        this.subMenu = "";
      }
    },
    lang(lang) {
      this.$cookies.set("lang", lang, {
        path: "/",
        secure: true,
        sameSite: "strict",
      });

      if (Object.keys(this.$i18n.messages[lang]).length > 0) {
        this.$i18n.locale = lang;
      } else {
        this.$i18n.locale = "en";
        this.$i18n.silentFallbackWarn = true;
      }
      this.$modal("langModal");
    },
    async logOut() {
      this.$router.push("/");
      this.$cookies.set("token", "");
      this.$store.dispatch("userOut");
    },
  },
};
</script>
