export default {
    state: {
        data: {
            TRC20: "",
            BEP20: "",
            ERC20: "",
            Polygon: "",
            token: {USDC: 0, USDT: 0},
            price: {},
            balance: 0
        },
    },
    mutations: {
        SET_WALLET(state, newData) {
            state.data = {...state.data, ...newData};
            state.data.balance = calculateBalance(state.data);
        },
        UPDATE_WALLET(state, newData) {
            if (state.data.token[newData.token]) {
                state.data.token[newData.token] -= newData.value;
                state.data.balance = calculateBalance(state.data);
            }
        },
    },
    actions: {
        async updateWallet({commit}, newData) {
            // Вызываем асинхронный код в действиях
            commit("UPDATE_WALLET", newData);
        },
        async walletData({commit}, newData) {
            // Вызываем асинхронный код в действиях
            commit("SET_WALLET", newData);
        },
    },
    getters: {
        wallet(state) {
            return state.data;
        },
    },
};

// Функция для вычисления баланса
function calculateBalance(data) {
    let balance = 0;
    for (const token in data.token) {
        if (data.token.hasOwnProperty(token)) {
            balance += data.token[token] * data.price[token];
        }
    }
    return balance;
}
