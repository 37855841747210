let openedModalId = false;

export async function modal(id) {
  const modal = document.getElementById(id);
  
  if (modal) {
    if (id === openedModalId) {
      modal.classList.remove('show');
      openedModalId = false;
      document.documentElement.style.overflow = 'auto';
    } else {
      if (openedModalId) {
        const currentModal = document.getElementById(openedModalId);
        currentModal.classList.remove('show');
      }
      modal.classList.add('show');
      openedModalId = id;
      document.documentElement.style.overflow = 'hidden';
    }
  } else {
      openedModalId = '';
      document.documentElement.style.overflow = "auto";
  }
}
