import { createStore } from 'vuex';
import app from "./app";
import user from './user';
import wallet from "./wallet";

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    modules: {
        user,
        app,
        wallet,
    },
});